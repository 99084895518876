import React from "react";
import "./App.css";
import moment from "moment";

function App() {
  let timeDate = () => moment().format("MMMM Do YYYY");

  return (
    <div id="bgImage">
      <div className="App">
        <div id="timeDate">{timeDate()}</div>
        <div className="jumbotron">
          <div className="spaceAround">
            <p className="myName">I'm Rebekah Brakebill.</p>
            <p className="myDesc">
              <em>Creator of multimedia</em> - websites to motion graphics and
              everything in between. <br></br>I have a passion for innovation and
              problem solving. Check out my resume&nbsp;
              <a
                className="resume-link"
                href="https://s3.us-east-2.amazonaws.com/rnbrakebill.com/brakebill-resume-20.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                here.
              </a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default App;
